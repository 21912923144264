const mobileMenuAction = (actionType: "toggle" | "remove") => {
  const menuButton = document.querySelector("span#menu-button");
  const logoImage = document.querySelector("div#thinknum-logo");

  if (menuButton && logoImage) {
    const menuButtonLines = menuButton.children;

    for (let i = 0; i < menuButtonLines.length; i++) {
      menuButtonLines[i].classList[actionType]("active");
    }
    logoImage.classList[actionType]("active");
    const menuMask = document.querySelector("div#mobile-menu-mask");
    menuMask && menuMask.classList[actionType]("active");

    const menuBackground = document.querySelector("div#mobile-menu-background");
    menuBackground && menuBackground.classList[actionType]("active");

    const navigationList = document.querySelector("ul#mobile-nav");
    navigationList && navigationList.classList[actionType]("active");

    document.body.classList[actionType]("freeze");
  }
};

export const toggleMobileMenu = () => {
  mobileMenuAction("toggle");
};

export const closeMobileMenu = () => {
  mobileMenuAction("remove");
};
