import "./layout.scss";
import {toggleMobileMenu} from "./_mobile_menu";

document.addEventListener("DOMContentLoaded", () => {
  // Mobile menu
  const menuButton = document.querySelector("span#menu-button");

  if (menuButton) {
    menuButton.addEventListener("click", toggleMobileMenu);
  }

  // Mobile menu items expansion
  const expanders = document.querySelectorAll(".menu-expander");

  for (let i = 0, len = expanders.length; i < len; i++) {
    const expander = expanders[i];

    expander.addEventListener("click", (event) => {
      const button = event.target as HTMLButtonElement;
      if (button) {
        const dropdown = button.parentNode?.querySelector(".dropdown-wrapper") as HTMLElement;
        if (dropdown) {
          const isExpanded = dropdown.clientHeight;
          dropdown.style.height = isExpanded ? "0px" : `${dropdown.scrollHeight}px`;
          expander.classList.toggle("expanded");
        }
      }
    });
  }

  // Login
  const loginErrorView = document.querySelector("div.error-row");
  if (loginErrorView) {
    const inputs = document.querySelectorAll("div.input-row input");
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].addEventListener("input", () => {
        loginErrorView.classList.add("isHidden");
      });
    }
  }

  // Sticky header
  // header height 35px + header padding-top 25px + header padding-bottom 25px + body padding-top 60px
  const headerHeight = 145;
  const stickyHeader = document.querySelector(".sticky-header");

  if (stickyHeader) {
    const adjustStickyHeaderVisibility = () => {
      if (window.scrollY > headerHeight) {
        stickyHeader.classList.add("visible");
      } else {
        stickyHeader.classList.remove("visible");
      }
    };

    adjustStickyHeaderVisibility();
    window.addEventListener("scroll", adjustStickyHeaderVisibility);
  }
});
